import React from 'react'
import SiteInfoStore from '../../stores/SiteInfoStore'

class EmailForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      currentUser: {},
    }
    this.registerForm = React.createRef()
  }

  componentDidMount() {
    const that = this
    SiteInfoStore.getCurrentUser(currentUser => {
      that.setState({ currentUser: currentUser })
    })
  }

  render() {
    const { buttonSize, buttonStyle = 'button-outline' } = this.props
    return (
      <form
        ref={this.registerForm}
        id='email-form'
        action='/users'
        className='register-form'
        acceptCharset='UTF-8'
        method='post'
      >
        <div className='input-container'>
          <input
            className='input-min'
            type='email'
            name='user[email]'
            placeholder='you@yourcompany.com'
            required
          />
          <button
            id='email-submit'
            className={`button ${buttonSize || 'button-md'} ${buttonStyle}`}
            data-disable-with={
              "<i class='order-detail-loading-icon fas fa-spinner-third fa-spin i-left'></i>" +
              'Submitting'
            }
            type='submit'
            form='email-form'
          >
            Get Started
          </button>
        </div>
      </form>
    )
  }
}
export default EmailForm
