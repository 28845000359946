import React from 'react'
import { useInView } from 'react-intersection-observer'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  backgroundUrl: string
  distanceFromViewport?: string
}

const LazyLoadBackground: React.FC<Props> = ({
  backgroundUrl,
  distanceFromViewport = '400px',
  children,
  ...rest
}) => {
  const inViewOptions = {
    triggerOnce: true,
    rootMargin: `${distanceFromViewport} 0px`,
  }

  const [viewRef, inView] = useInView(inViewOptions)

  const style = {
    backgroundImage: inView ? `url(${backgroundUrl})` : undefined,
  }

  return (
    <div ref={viewRef} style={style} {...rest}>
      {children}
    </div>
  )
}

export default LazyLoadBackground
